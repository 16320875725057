import React from "react";
import BaseText, { FontWeight, TextSize } from "../baseText";
import InformationButton from "../buttons/Information";
import styles from "./TitleBodyBlock.module.css";

type Props = {
  title: string;
  body: string;
  hintText: string;
};

const TitleBodyBlock = ({ title, body, hintText }: Props) => {
  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <BaseText
            text={title}
            fontWeight={FontWeight.SemiBold}
            size={TextSize.Large}
          />
        </div>
        <div className={styles.marginRightAuto}>
          <InformationButton size={18} tooltipText={hintText} />
        </div>
      </div>
      <div className={styles.body}>{body}</div>
    </>
  );
};
export { TitleBodyBlock };
