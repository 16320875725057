import React from "react";
import BaseText, { FontWeight, TextSize } from "../baseText";
import BaseButton from "../buttons/baseButton";
import { OpenseaIcon } from "../icons/openseaIcon";
import styles from "./OpenseaButton.module.css";

type Props = {
  onClick: () => void;
};

const OPENSEA_IMAGE_SIZE = 20;

const OpenseaButton = ({ onClick }: Props) => {
  return (
    <BaseButton className={styles.openseaLink} onClick={onClick}>
      <div className={styles.openseaLogo}>
        <OpenseaIcon size={OPENSEA_IMAGE_SIZE} />
      </div>
      <BaseText
        size={TextSize.Mini}
        fontWeight={FontWeight.SemiBold}
        text={"View on Opensea"}
        className={styles.openseaText}
      />
    </BaseButton>
  );
};

export { OpenseaButton };
