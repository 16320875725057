import React from "react";
import { colour } from "../../global/theme";

type Props = {
  size: number;
  lineFill?: string;
  backgroundFill?: string;
};
const SendIcon = ({
  size,
  lineFill = colour.black,
  backgroundFill = colour.white,
}: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Send Icon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Art-Detail" transform="translate(-908.000000, -214.000000)">
          <g
            id="Monkey-Card-Copy-6"
            transform="translate(524.000000, 178.000000)"
          >
            <g id="Group-6" transform="translate(384.000000, 36.000000)">
              <circle
                id="Oval"
                stroke={lineFill}
                strokeWidth="0.5"
                fill={backgroundFill}
                cx="11"
                cy="11"
                r="10.75"
              ></circle>
              <path
                d="M5.20888,9.99106333 L15.5293661,6.03389294 C15.6497354,5.97429524 15.7944443,5.99453324 15.8937372,6.08485137 C15.9930301,6.17516951 16.0265278,6.31703058 15.9780829,6.44205166 L12.0293752,16.7901283 L12.0293752,16.7901283 C11.9831906,16.9117948 11.868696,16.9942561 11.7383749,16.9997125 C11.6080537,17.0051689 11.4870327,16.9325685 11.430787,16.8151907 L9.83963725,13.5320192 L12.997706,9.02347655 L8.48630742,12.1643295 L5.1846493,10.5871899 C5.06747456,10.5310195 4.99500266,10.4107409 5.00026886,10.2811814 C5.00553505,10.1516219 5.08753098,10.0375891 5.20888,9.99106333 Z"
                id="Path"
                fill={lineFill}
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { SendIcon };
