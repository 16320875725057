import React from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

const MODAL_ID = "custom-modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  dismissable?: boolean;
};

const Modal = ({ isOpen, onClose, dismissable = true, children }: Props) => {
  const onOverlayClick = () => {
    if (!dismissable) return;
    onClose();
  };
  if (!isOpen) return null;

  const portalElement = document.getElementById(MODAL_ID);

  if (portalElement === null) return null;

  return ReactDOM.createPortal(
    <>
      <div className={styles.overlay} onClick={onOverlayClick} />
      <div className={styles.container}>{children}</div>
    </>,
    portalElement
  );
};

export { Modal, MODAL_ID };
