import { useState } from "react";
import MetamaskConnectUtils from "../../components/metamaskConnect/MetamaskConnect.utils";
import { useGlobalState } from "../../global/hooks";

type TransferProposalProps = {
  toAddress: string;
  tokenId: string;
};

const useTransferProposalData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const globalState = useGlobalState();

  const transferProposal = async ({
    toAddress,
    tokenId,
  }: TransferProposalProps) => {
    const fromAddress = globalState.state.ethPublicKey;
    if (!fromAddress) {
      return;
    }
    setIsLoading(true);
    console.log({ toAddress });
    console.log({ fromAddress });
    console.log({ tokenId });
    const result = await MetamaskConnectUtils.transferNFT(
      toAddress,
      fromAddress,
      tokenId
    );
    setIsLoading(false);
    return result;
  };

  return { transferProposal, isLoading };
};

export { useTransferProposalData };
