import styles from "./Pill.module.css";
import { colour } from "../../global/theme";

import BaseText, { Font, TextSize } from "../baseText";
import { conditionalRender } from "../../utils/helpers";
import { ErrorIconOutline, SuccessIcon } from "../icons";

const ICON_SIZE = 15;

export enum PillType {
  Error = "error",
  Warning = "warning",
  Highlight = "highlight",
  Success = "success",
}
type Props = {
  text: string;
  type: PillType;
  hideIcon?: boolean;
  textSize?: TextSize;
  font?: Font;
  bold?: boolean;
};

const getIcon = (type: PillType) => {
  if (type === PillType.Highlight) return null;
  if (type === PillType.Success) {
    return (
      <SuccessIcon
        height={ICON_SIZE}
        width={ICON_SIZE}
        lineFill={colour.success}
        backgroundFill={colour.white}
      />
    );
  }
  return <ErrorIconOutline height={ICON_SIZE} width={ICON_SIZE} />;
};

const getPillColour = (type: PillType): string => {
  switch (type) {
    case PillType.Warning:
      return colour.warning;
    case PillType.Error:
      return colour.error;
    case PillType.Highlight:
      return colour.secondaryHighlight;
    case PillType.Success:
      return colour.success;
  }
};

const getTextColour = (type: PillType) => {
  if (type === PillType.Success) {
    return colour.white;
  }
  return colour.black;
};

const Pill = ({
  type,
  text,
  hideIcon = false,
  textSize = TextSize.Mini,
  bold = false,
  font = Font.Primary,
}: Props) => {
  const fontWeightClass = bold ? styles.bold : styles.regular;
  const padding = hideIcon ? styles.defaultPadding : styles.iconPadding;

  return (
    <div
      className={[styles.text, padding].join(" ")}
      style={{ backgroundColor: getPillColour(type) }}
    >
      {conditionalRender(
        !hideIcon,
        <div className={styles.icon}>{getIcon(type)}</div>
      )}
      <BaseText
        text={text}
        size={textSize}
        font={font}
        style={{ color: getTextColour(type) }}
      />
    </div>
  );
};

export { Pill };
