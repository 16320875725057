import React, { useRef, useState } from "react";
import { Tooltip } from "reactstrap";
import styles from "./InformationButton.module.css";
import QuestionMark from "../../../assets/question_mark_icon.svg";
import Image from "next/image";
interface Props {
  tooltipText: string;
  size?: number;
}
const DEFAULT_SIZE = 22;

const InformationButton = ({ tooltipText, size }: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLInputElement>(null);
  const toggle = () => setIsTooltipOpen(!isTooltipOpen);

  const sizeStyle = {
    height: size ?? DEFAULT_SIZE,
    width: size ?? DEFAULT_SIZE,
  };
  return (
    <div>
      <div ref={tooltipRef} style={sizeStyle} className={styles.imageContainer}>
        <Image
          src={QuestionMark}
          width={size ?? DEFAULT_SIZE}
          height={size ?? DEFAULT_SIZE}
        />
      </div>
      <Tooltip
        target={tooltipRef}
        placement="right"
        toggle={toggle}
        isOpen={isTooltipOpen}
        trigger="hover"
      >
        <span>{tooltipText}</span>
      </Tooltip>
    </div>
  );
};

export { InformationButton };
