import React, { useEffect, useState } from "react";
import styles from "./ProposalDetails.module.css";
import TitleBodyBlock from "../titleBodyBlock";
import FileFeedView from "../ffileFeed";
import { conditionalRender, mergeArray } from "../../utils/helpers";
import { Proposal, ProposalStatus } from "../../hooks/useProposalData";
import Pill, { PillType } from "../pill";
import BaseText, { Font, FontWeight, TextSize } from "../baseText";
import BaseButton from "../buttons/baseButton";
import { useCreateProposalData } from "../../hooks/useCreateProposalData/useCreateProposalData";
import StatusMessageView, { Status } from "../statusMessage";
import { useCommitProposalData } from "../../hooks/useCreateProposalData/useCommitProposalData";
import { EtherscanIcon } from "../icons/etherscanIcon";
import RoundedButton from "../../roundedButton";
import { FileType } from "../createProposalInputFiles/createProposalInputFiles.view";
import {
  getEtherScanTransactionURL,
  getOpenSeaTokenURL,
} from "../../utils/blockchainConfig";
import { useTransferProposalData } from "../../hooks/useTransferProposalData";
import MetamaskConnectUtils from "../metamaskConnect/MetamaskConnect.utils";
import { OpenseaButton } from "../openseaButton";
import { ThumbnailImageView } from "../thumbnailImageView";
import { ProposalMetatdataView } from "../proposalMetadataView/ProposalMetadata.view";
import { SendIcon } from "../icons/sendIcon";
import { IconButton } from "../iconButton";
import { Modal } from "../modal";
import { SendProposalView } from "../sendProposalView";

const ETHERSCAN_IMAGE_SIZE = 20;

interface ProposalDetailsProps {
  proposal?: Proposal;
  onCommitSuccess: () => void;
}

const notCommittedMessage = "Not committed to the blockchain";

const ProposalDetails = ({
  proposal,
  onCommitSuccess,
}: ProposalDetailsProps) => {
  //TODO - Come up with approach to show proposal sent status
  const [hasSentProposal, setHasSentProposal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { setProposalSubmissionState } = useCreateProposalData();
  const [proposalTokenId, setProposalTokenId] = useState<number | undefined>();

  const {
    commitProposal,
    isLoading: isLoadingCommit,
    errorMessage: commitErrorMessage,
  } = useCommitProposalData({
    proposalId: proposal?.id,
    onSuccess: onCommitSuccess,
  });

  const { transferProposal, isLoading: isLoadingNFTTransfer } =
    useTransferProposalData();

  const thumnailImageUrl = proposal?.files.find(
    (file) => file.type === FileType.Thumbnail
  )?.publicUrl;

  const [sendProposalError, setSendProposalError] = useState<
    undefined | string
  >(undefined);

  const genericFiles =
    proposal?.files.filter((file) => file.type === FileType.Generic) ?? [];

  useEffect(() => {
    setSendProposalError(undefined);
    if (!proposal?.status) return;
    setProposalSubmissionState(proposal.status);
  }, [proposal]);

  const fetchTokenId = async () => {
    if (
      proposal?.status === ProposalStatus.Confirmed &&
      !!proposal?.blockChainTransactionid
    ) {
      const tokenId = await MetamaskConnectUtils.getTokenId(
        proposal.blockChainTransactionid ?? ""
      );
      setProposalTokenId(tokenId);
    }
  };

  useEffect(() => {
    //TODO- remove once tokenId is fetched and sent to backend during mint
    fetchTokenId();
  }, [proposal]);

  if (!proposal) {
    return (
      <div className={styles.proposalDetails}>
        <div className={styles.placeholderText}>No proposal selected</div>
      </div>
    );
  }

  const getFormattedDate = () => {
    return new Date(proposal.dateCreated).toLocaleDateString("en-UK");
  };

  const renderPill = () => {
    if (proposal.status === ProposalStatus.Confirmed) {
      return (
        <div className={styles.rowToColumn}>
          <Pill type={PillType.Success} text={"Committed to the blockchain"} />
          {renderOpenseaLink()}
          {renderEtherscanLink()}
        </div>
      );
    }
    return <Pill type={PillType.Warning} text={notCommittedMessage} />;
  };

  const onCommitClick = async () => {
    commitProposal({
      proposalUid: proposal.id,
      proposalHash: proposal.hashedContents,
      metadataLocation:
        proposal.artifactLocations?.decentralisedMetadataLocation,
    });
    // TODO: Update proposal card to right status
  };

  const renderCommitButton = () => {
    if (proposal.status === ProposalStatus.Confirmed) return null;
    return (
      <>
        <RoundedButton
          text="Commit to the blockchain"
          isLoading={isLoadingCommit}
          onClick={onCommitClick}
        />
        {conditionalRender(
          !!isLoadingCommit,
          <StatusMessageView
            status={Status.Warniing}
            text={"This may take a few seconds"}
          />
        )}
        {conditionalRender(
          !!commitErrorMessage,
          <StatusMessageView status={Status.Error} text={commitErrorMessage!} />
        )}
      </>
    );
  };

  const onEtherscanLinkPress = () => {
    if (!proposal.blockChainTransactionid) return;
    const etherscanUrl = getEtherScanTransactionURL(
      proposal.blockChainTransactionid
    );
    window.open(etherscanUrl);
  };

  const renderEtherscanLink = () => (
    <BaseButton className={styles.etherscanLink} onClick={onEtherscanLinkPress}>
      <div className={styles.etherscanLogo}>
        <EtherscanIcon size={ETHERSCAN_IMAGE_SIZE} />
      </div>
      <BaseText
        size={TextSize.Mini}
        fontWeight={FontWeight.SemiBold}
        text={"View on Etherscan"}
        className={styles.etherscanText}
      />
    </BaseButton>
  );

  const onOpenseaLinkClick = () => {
    if (!proposalTokenId) return;
    const etherscanUrl = getOpenSeaTokenURL(`${proposalTokenId}`);
    window.open(etherscanUrl);
  };

  const renderOpenseaLink = () =>
    proposalTokenId && (
      <div className={styles.openseaButton}>
        <OpenseaButton onClick={onOpenseaLinkClick} />
      </div>
    );

  const onSendProposal = async (address: string) => {
    setSendProposalError(undefined);

    // //TODO- remove once tokenId is fetched and sent to backend during mint
    // const tokenId = await MetamaskConnectUtils.getTokenId(
    //   proposal.blockChainTransactionid ?? ""
    // )

    if (!proposalTokenId) {
      setSendProposalError("An error occured fetching your tokenId");
      setHasSentProposal(false);
      return;
    }

    const result = await transferProposal({
      toAddress: address,
      tokenId: `${proposalTokenId}`,
    });

    if (!result?.success) {
      setSendProposalError(result?.error);
      setHasSentProposal(false);
      return;
    }
    setHasSentProposal(true);
  };

  const renderSendView = () => {
    if (
      proposal.status !== ProposalStatus.Confirmed ||
      !proposalTokenId ||
      hasSentProposal
    ) {
      return null;
    }

    return (
      <IconButton onClick={onSendClick}>
        <SendIcon size={30} />
      </IconButton>
    );
  };

  const onSendClick = () => {
    setModalIsOpen(true);
  };

  const renderHeaderRow = () => {
    return (
      <div className={styles.headerRow}>
        <div className={styles.row}>
          <h2 className={styles.proposalName}>{proposal.name}</h2>
          {renderSendView()}
        </div>
        <Pill
          type={PillType.Highlight}
          text={proposal.creator}
          hideIcon
          font={Font.Secondary}
          textSize={TextSize.Small}
        />
        <div className={styles.row}>
          <div className={styles.createdDate}>
            Created: {getFormattedDate()}
          </div>
        </div>
        <div
          className={mergeArray([
            styles.rowFlexStart,
            styles.defaultBottomMargin,
          ])}
        >
          {renderPill()}
        </div>
      </div>
    );
  };

  const onSendModalClose = () => setModalIsOpen(false);
  return (
    <div className={styles.proposalDetails}>
      <div className={styles.rowToColumn}>
        {renderHeaderRow()}
        {!!thumnailImageUrl && (
          <ThumbnailImageView imageUrl={thumnailImageUrl} />
        )}
      </div>
      <div className={styles.description}>{proposal.description}</div>
      {genericFiles?.length !== 0 && <FileFeedView files={genericFiles} />}
      <div className={styles.defaultBottomMargin}>
        <TitleBodyBlock
          title="Content hash:"
          body={proposal.hashedContents ?? notCommittedMessage}
          hintText="This is a fingerprint of your proposal that is saved on the blockchain. It is used to prove you had access to the proposal information at the time of committing it to the blockchain." //TODO: Update description
        />
      </div>
      <div className={styles.defaultBottomMargin}>
        <TitleBodyBlock
          title="Transaction Id:"
          body={proposal.blockChainTransactionid ?? notCommittedMessage}
          hintText="This is the ethereum blockchain transaction id" //TODO: Update description
        />
      </div>
      <div className={styles.defaultBottomMargin}>
        <TitleBodyBlock
          title="Metadata location:"
          body={
            proposal.artifactLocations?.decentralisedMetadataLocation ??
            notCommittedMessage
          }
          hintText="This is where the metadata describing your proposal and associated files are stored" //TODO: Update description
        />
      </div>
      <ProposalMetatdataView metadata={proposal?.metadata} />
      {renderCommitButton()}
      <Modal
        isOpen={modalIsOpen}
        onClose={onSendModalClose}
        dismissable={!isLoadingNFTTransfer}
      >
        <SendProposalView
          proposalId={proposal.id}
          isLoading={isLoadingNFTTransfer}
          onSubmitAddress={onSendProposal}
          errorMessage={sendProposalError}
          hasSent={hasSentProposal}
          onClose={onSendModalClose}
        />
      </Modal>
    </div>
  );
};

export default ProposalDetails;
