import React, { useEffect, useState } from "react";
import Image from "next/image";
import styles from "./ThumbnailImageView.module.css";
import { mergeArray } from "../../utils/helpers";

type Props = { imageUrl: string };
const ThumbnailImageView = ({ imageUrl }: Props) => {
  const [showErrorImageView, setShowErrorImageView] = useState(false);

  useEffect(() => {
    setShowErrorImageView(false);
  }, [imageUrl]);

  const onThumbnailLoadError = () => {
    setShowErrorImageView(true);
  };
  const renderThumbnail = () => {
    if (!imageUrl) {
      return null;
    }

    if (showErrorImageView) {
      return <div className={mergeArray([styles.errorCard, styles.image])} />;
    }

    return (
      <div className={styles.thumbnail}>
        <Image
          className={styles.image}
          layout="fill"
          objectFit="cover"
          src={imageUrl}
          onError={onThumbnailLoadError}
        />
      </div>
    );
  };

  return <>{renderThumbnail()}</>;
};

export { ThumbnailImageView };
