import React from "react";
import styles from "./IconButton.module.css";

type Props = {
  onClick: () => void;
  children: JSX.Element;
};

const IconButton = ({ onClick, children }: Props) => {
  return (
    <div className={styles.container} onClick={onClick}>
      {children}
    </div>
  );
};

export { IconButton };
