import React, { useEffect, useState } from "react";
import RoundedButton from "../../roundedButton";
import { conditionalRender } from "../../utils/helpers";
import BaseText, { FontWeight, TextSize } from "../baseText";
import StatusMessageView, { Status } from "../statusMessage";
import TextInput from "../textInput";
import styles from "./SendProposalView.module.css";

type Props = {
  proposalId: string;
  isLoading: boolean;
  onSubmitAddress: (text: string) => void;
  errorMessage?: string;
  hasSent: boolean;
  onClose: () => void;
};

const SendProposalView = ({
  proposalId,
  isLoading,
  onSubmitAddress,
  errorMessage,
  hasSent,
  onClose,
}: Props) => {
  const [address, setAddress] = useState("");
  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    setAddress("");
    setValidationErrorMessage(undefined);
  }, [proposalId]);

  const validateAddress = () => {
    if (address === "") {
      setValidationErrorMessage("Invalid wallet address");
      return false;
    }
    return true;
  };

  const onSendClick = () => {
    if (!validateAddress()) {
      return;
    }
    onSubmitAddress(address);
    return;
  };

  const onSendTextChange = (text: string) => {
    setValidationErrorMessage(undefined);
    setAddress(text);
  };

  const renderSendView = () => (
    <>
      <div className={styles.textInput}>
        <TextInput
          disabled={!!isLoading}
          value={address}
          onTextChange={onSendTextChange}
          placeholder="Public address (0x...)"
        />
      </div>
      {conditionalRender(
        !!validationErrorMessage || !!errorMessage,
        <div className={styles.errorMessage}>
          <StatusMessageView
            text={errorMessage ?? validationErrorMessage ?? ""}
            status={Status.Error}
          />
        </div>
      )}
      <div className={styles.textButton}>
        <RoundedButton
          text="Send"
          onClick={onSendClick}
          isLoading={isLoading}
        />
      </div>
    </>
  );

  const renderSentView = () => (
    <>
      <BaseText text="Sent to:" fontWeight={FontWeight.Medium} />
      <BaseText text={address} size={TextSize.Small} />
      <div className={styles.textButton}>
        <RoundedButton text="Done" onClick={onClose} />
      </div>
    </>
  );

  return (
    <>
      <div className={styles.title}>
        <BaseText
          text="Send Proposal"
          fontWeight={FontWeight.SemiBold}
          size={TextSize.Large}
        />
      </div>
      <div className={styles.container}>
        {conditionalRender(hasSent, renderSentView(), renderSendView())}
      </div>
    </>
  );
};

export { SendProposalView };
