import dynamic from "next/dynamic";
const DynamicReactJson = dynamic(() => import("react-json-view"), {
  ssr: false,
});
import styles from "./ProposalMetadata.module.css";
import BaseText, { FontWeight, TextSize } from "../baseText";
import { conditionalRender } from "../../utils/helpers";

type Props = {
  metadata?: object;
};

const ProposalMetatdataView = ({ metadata }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.metadataTitle}>
          <BaseText
            text="Metadata:"
            fontWeight={FontWeight.SemiBold}
            size={TextSize.Large}
          />
        </div>
        {conditionalRender(
          Object.keys(metadata ?? {}).length > 0,
          <div className={styles.metadataContainer}>
            <DynamicReactJson
              src={metadata ?? {}}
              name={null}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              style={{
                overflow: "scroll",
                paddingBottom: "1em",
                fontSize: "0.8em",
              }}
            />
          </div>,
          <div className={styles.metadataEmptyView}>
            <BaseText text="None" />
          </div>
        )}
      </div>
    </div>
  );
};

export { ProposalMetatdataView };
